<div class="col-md-12 col-lg-12 mainpanel">
          <div class="row">
              <div class="col-md-7">
                    <img class="image" src="./assets/images/mtee_slideshow_02.jpg" alt="EUMTEE">
              </div>

              <div class="col-md-5">
                  <div class="" style="background-color: rgb(251, 251, 251)">
                    <div *ngIf="showLogin">
                      <app-log-in ></app-log-in>
                    </div>
                    <div *ngIf="showRegister">
                      <app-register ></app-register>
                    </div>
                  </div>
              </div>
          </div>

          <div class="row">
                <div class="overlay col-md-12 col-lg-12 text-center">
                       <p>{{homeComponentItems.tabcontent}}</p>
                       <p>{{homeComponentItems.mtee_funding_messaging}}</p>
                </div>
          </div>
</div>
