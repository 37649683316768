import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError, tap, retry } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const headers = new HttpHeaders();
headers.set('Content-Type', 'application/json; charset=utf-8');
headers.set('Accept', 'application/json');
// const endpoint = 'http://127.0.0.1:5000/';
const endpoint = 'https://eumtee.lut.fi/pbp/';
export class WebserviceCallerService {
    constructor(http) {
        this.http = http;
        // Http Options
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            })
        };
    }
    extractData(res) {
        const body = res['result'];
        // console.log('Inside extractData in webservice caller');
        return body || {};
    }
    //
    getAllLanguage() {
        // console.log('Inside getAllLanguage in webservice caller');
        return this.http.get(endpoint + 'getAllLanguage').pipe(map(this.extractData));
    }
    //
    getErrorList() {
        // console.log('Inside getErrorList in webservice caller');
        return this.http.get(endpoint + 'getErrorList').pipe(map(this.extractData));
    }
    //
    getSuccessMessageList() {
        // console.log('Inside getSuccessMessageList in webservice caller');
        return this.http.get(endpoint + 'getSuccessMessageList').pipe(map(this.extractData));
    }
    //
    getCountryList() {
        // console.log('Inside getCountryList in webservice caller');
        return this.http.get(endpoint + 'getCountryList').pipe(map(this.extractData));
    }
    //
    getAllStaticContent() {
        // console.log('Inside getAllStaticContent in webservice caller');
        return this.http.get(endpoint + 'getAllStaticContent').pipe(map(this.extractData));
    }
    //
    getQuestionByTheme() {
        // console.log('Inside getQuestionByTheme in webservice caller');
        return this.http.get(endpoint + 'getQuestions').pipe(map(this.extractData));
    }
    //
    getQuestionThemeName() {
        // console.log('Inside getQuestionThemeName in webservice caller: ');
        return this.http.get(endpoint + 'getQuestionThemeName').pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`Fetching theme names`)), catchError(this.handleError('getQuestionThemeName')));
    }
    //
    saveUserResponse(response) {
        // console.log('Inside saveUserResponse in webservice caller with response as below : ');
        // console.log(response);
        return this.http.post(endpoint + 'saveUserResponse', JSON.stringify(response)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`added new response w/ time=${response}`)), catchError(this.handleError('saveUserResponse')));
    }
    //
    registerUser(requestBody) {
        // console.log('Inside registerUser in webservice caller with requestBody as below : ');
        // console.log(requestBody);
        return this.http.post(endpoint + 'registerUser', JSON.stringify(requestBody)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)), catchError(this.handleError('registerUser')));
    }
    //
    loginUser(requestBody) {
        // console.log('Inside loginUser in webservice caller with requestBody as below : ');
        // console.log(requestBody);
        return this.http.post(endpoint + 'validateUser', JSON.stringify(requestBody)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)), catchError(this.handleError('validateUser')));
    }
    //
    getFeedbackParameters() {
        // console.log('Inside getFeedbackParameters in webservice caller: ');
        return this.http.get(endpoint + 'getFeedbackParameters').pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`Fetching feedback parameters`)), catchError(this.handleError('getFeedbackParameters')));
    }
    //
    getAllResponseOfUser(requestBody1, requestBody2) {
        // console.log('Inside getAllResponseOfUser in webservice caller: ');
        return this.http.get(endpoint + 'getAllResponseOfUser?user=' + requestBody1.username
            + '&selectedDate=' + requestBody2.selectedDate).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`Fetching all response for user`)), catchError(this.handleError('getAllResponseOfUser')));
    }
    //
    getAllDatesForUser(requestBody) {
        // console.log('Inside getAllDatesForUser in webservice caller: ');
        return this.http.get(endpoint + 'getAllDatesForUser?user=' + requestBody.username).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((response) => console.log(`Fetching all response dates for user`)), catchError(this.handleError('getAllDatesForUser')));
    }
    //
    recoverUser(requestBody) {
        // console.log('Inside recoverUser in webservice caller: ');
        // console.log(requestBody);
        return this.http.post(endpoint + 'validateAccount', JSON.stringify(requestBody)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)), catchError(this.handleError('validateAccount')));
    }
    //
    resetPassword(requestBody) {
        // console.log('Inside resetPassword in webservice caller: ');
        // console.log(requestBody);
        return this.http.post(endpoint + 'resetPassword', JSON.stringify(requestBody)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)), catchError(this.handleError('resetPassword')));
    }
    //
    updatePassword(requestBody) {
        // console.log('Inside updatePassword in webservice caller: ');
        // console.log(requestBody);
        return this.http.post(endpoint + 'updatepassword', JSON.stringify(requestBody)).pipe(
        // tslint:disable-next-line:no-shadowed-variable
        tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)), catchError(this.handleError('updatepassword')));
    }
    //
    //
    verifyReportKey(request) {
        return this.http.get(endpoint + 'verifyReportKey?reportKey=' + request.key).pipe(map(this.extractData));
    }
    //
    getDownloadData(useRequest) {
        console.log('Inside API Service for downloadReportBetweenDates');
        const isoStartDate = new Date(useRequest.startDate).toISOString();
        const isoEndDate = new Date(useRequest.endDate).toISOString();
        return this.http.get(endpoint
            + 'downloadReportBetweenDates?startDate=' + isoStartDate
            + '&endDate=' + isoEndDate
            + '&report_key=' + useRequest.report_key, { responseType: 'blob' }).pipe(retry(1), catchError(this.handleError('downloadReportBetweenDates')));
    }
    //
    //
    handleError(operation = 'operation', result) {
        // console.log('Inside handleError in webservice caller');
        return (error) => {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            // console.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
WebserviceCallerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebserviceCallerService_Factory() { return new WebserviceCallerService(i0.ɵɵinject(i1.HttpClient)); }, token: WebserviceCallerService, providedIn: "root" });
