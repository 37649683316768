import { Component, OnInit, VERSION, ViewChild, AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {CookieService} from 'ngx-cookie-service';
import { MatStepper } from '@angular/material';
import {ShowMessageService} from '../show-message.service';
import {MatSnackBar} from '@angular/material/snack-bar';


export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  questionComponentItems: any = {};
  userLoginDetails: any = {};
  countryList: any = [];
  selectedCountry = '';
  errorMsg: any = {};
  successMsg: any = {};
  private ngVersion: string = VERSION.full;
  public selectedLanguage: string;
  private questionListByTheme: any = [];
  public ListByThemeName: any = [];
  private  firstQuestionPage = '';
  private  prevQuestionPage = '';
  private  nowQuestionPage = '';
  private  nextQuestionPage = '';
  private  lastQuestionPage = '';
  private  questionListSelectedTheme: any = [];
  public  listTheme: any = [];
  private  userResponse = {
    end_time: undefined,
    mtee_user_response: undefined,
    start_time: undefined,
    mtee_user_language: '',
    mtee_user_yob: undefined,
    mtee_user_gender: undefined,
    mtee_user_id: undefined,
  };


  // Only required when not passing the id in methods
  activedStep = 0;
  answerModel = {
    SC01: undefined,
    SC02: undefined,
    SC03: undefined,
    SC04: undefined,
    SC05: undefined,
    SC06: undefined,
    SC07: undefined,
    SC08: undefined,
    SC09: undefined,
    SC10: undefined,
    SC11: undefined,
    SC12: undefined,
    SC13: undefined,
    SC14: undefined,
    SC15: undefined
  };
  scaleQuestionsHandled = false;
  totalQuestions = 0;
  progress = 0;
  totalAnswered = 0;
  pageQuestionLength = 0;
  progressBarValue = 0;
  questionsInPage: any = [];
  questionsAnswered: any = [];

  scaleSize = 30;
  // tslint:disable-next-line:variable-name
  updateSetting(event, mtee_question_id, questionType, indexPage) {
    this.scaleSize = event.value;
    this.answeredQuestion(event, mtee_question_id, this.scaleSize, questionType, indexPage);
    // console.log('scale value registered: ' + this.scaleSize);
  }
  //
  // tslint:disable-next-line:variable-name
  onSelectionCountry(counrty, mtee_question_id, questionType, indexPage) {
    // console.log('----counrty--:', counrty.value);
    this.selectedCountry = counrty.value;
    this.answeredQuestion(event, mtee_question_id, this.selectedCountry, questionType, indexPage);
  }
  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
    this.totalAnswered = this.pageQuestionLength;
    this.pageQuestionLength = 0;
    // console.log(this.answerModel);
    // console.log('Page submitted');
    // console.log(this.totalAnswered);
  }

  submit() {
    this.userResponse.mtee_user_response = this.answerModel;
    this.userResponse.end_time = new Date();
    this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.userResponse.mtee_user_language = this.selectedLanguage;
    // console.log(this.userResponse);
    this.saveUserResponse();
  }



  constructor(public rest: WebserviceCallerService,
              private route: ActivatedRoute,
              private router: Router,
              private dataBus: DataShareService,
              private formBuilder: FormBuilder,
              public cookieService: CookieService,
              public showMessageService: ShowMessageService,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.dataBus.questionPageStatic.subscribe(message => this.questionComponentItems = message);
    this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.dataBus.userLoginResult.subscribe(message => this.userLoginDetails = message);
    this.cookieService.set('showFeedback', 'Y',  undefined, '/', undefined, true);
    // console.log(this.selectedLanguage);
    this.getThemeNames();
    this.getQuestionByTheme();
    this.getCountryList();
  }

  getThemeNames() {
    // console.log('Inside getThemeNames in question component caller');
    this.ListByThemeName = [];
    this.rest.getQuestionThemeName().subscribe
    ((data: {
        result: any;
      }) => {
        // console.log('logging theme list content');
        this.ListByThemeName = data.result;
        // console.log(this.ListByThemeName);
      }
    );

  }
  saveUserResponse() {
    // console.log('Inside saveUserResponse in question component caller');
    this.rest.saveUserResponse(this.userResponse).subscribe(res => {
          // console.log(res);
          if (res.status === 'success') {
            this.successMsg = this.showMessageService.getSuccessMessageByCode('905');
            this.snackBar.open(this.successMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
            });
            this.router.navigate(['feedback']);
          }
        }, (err) => {
            this.errorMsg = this.showMessageService.getErrorMessageByCode('111');
            this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
            });
          // console.log(err);
        });
  }
  //
  getCountryList() {

    // console.log('Inside getCountryList in question component caller');
    this.countryList = [];
    this.rest.getCountryList().subscribe
    (
      (data: {
        result: any;
      }) => {
        // console.log('logging Country data');
        // console.log(data);
        this.countryList = data;
      }
      );
  }
  //
  getQuestionByTheme() {
    // console.log('Inside getQuestionByTheme in question component caller');
    this.questionListByTheme = [];
    this.rest.getQuestionByTheme().subscribe
    ((data: {}) => {
        // console.log('logging question content');
        // console.log(data);
        this.questionListByTheme = data;
        this.totalQuestions = this.questionListByTheme.length;
        // console.log(this.totalQuestions);
        this.userResponse.start_time = new Date();
        this.userResponse.mtee_user_id = this.userLoginDetails.mtee_user_id;
        this.userResponse.mtee_user_yob = this.userLoginDetails.mtee_user_yob;
        this.userResponse.mtee_user_gender = this.userLoginDetails.mtee_user_gender;
        this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
        // console.log('logging question language to be shown');
        // console.log(this.selectedLanguage);
        this.useQuestionController(this.questionListByTheme);
      }
    );
  }

  useQuestionController(allQuestions) {
    // console.log('¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤');
    // This function prepares the question controller after getting all fetched questions and arranging them in pages based on theme name
    for ( const eachQuestion of allQuestions) {
      // console.log(eachQuestion.mtee_question_level);
      // console.log(eachQuestion.mtee_question_id);
      // console.log(eachQuestion.mtee_question_theme_name);
      // console.log(eachQuestion.mtee_question_type_name);
      // console.log(eachQuestion.questionType);
      if (this.firstQuestionPage === '') {
        this.firstQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('firstQuestionPage');
        // console.log(this.firstQuestionPage);
      }
      if (this.firstQuestionPage !== '' && this.prevQuestionPage === '') {
        this.prevQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('prevQuestionPage');
        // console.log(this.prevQuestionPage);
      }
      if (this.prevQuestionPage !== '' && this.prevQuestionPage !== eachQuestion.mtee_question_theme_name) {
        this.nextQuestionPage = eachQuestion.mtee_question_theme_name;
        this.lastQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('nextQuestionPage');
        // console.log(this.nextQuestionPage);
        this.questionsInPage.push(this.prevQuestionPage = this.questionListSelectedTheme.length);
        this.questionsAnswered.push(0);
        // console.log('questionsInPage ');
        // console.log(this.questionsInPage);
        // console.log('questionsAnswered ');
        // console.log(this.questionsAnswered);
        this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
        this.questionListSelectedTheme = [];
        this.prevQuestionPage = '';
        // console.log(this.listTheme);
      }
      this.questionListSelectedTheme.push(eachQuestion);
    }
    // console.log('lastQuestionPage');
    // console.log(this.lastQuestionPage);
    this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
    this.questionListSelectedTheme = [];
    this.prevQuestionPage = '';
    // console.log(this.listTheme);
  }

  answeredQuestion(event: Event, questionId: string | number, questionAnswer: string | number, questionType: string | number, indexPage) {
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      if ((indexPage === 2) && (this.scaleQuestionsHandled === false)) {
        this.fillScaleQuestions();
      }
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered);
    }
    //
    // console.log(questionId);
    // console.log(questionAnswer);
    this.answerModel[questionId] = questionAnswer;
    if (indexPage !== 2 && this.scaleQuestionsHandled === false ) {
      this.pageQuestionLength = -15;
      // console.log('Index is less than 2 ' + this.pageQuestionLength);
    } else {
      // console.log('Index other ' + this.pageQuestionLength);
      this.pageQuestionLength = 0;
    }
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      this.pageQuestionLength = this.pageQuestionLength + 1;
      this.progress = this.pageQuestionLength;
      this.progressBarValue = (this.progress / this.totalQuestions) * 100;
      // console.log('answerModel Length');
      // console.log(this.pageQuestionLength);
    }
    if (this.scaleQuestionsHandled === true) {
      // console.log('scale handled');
    }
    // console.log(this.answerModel);
  }
  //
  fillScaleQuestions() {
    // console.log('Inside fillScaleQuestions: ');
    // console.log(this.answerModel);
    this.scaleQuestionsHandled = true;
    this.answerModel.SC01 = 0;
    this.answerModel.SC02 = 0;
    this.answerModel.SC03 = 0;
    this.answerModel.SC04 = 0;
    this.answerModel.SC05 = 0;
    this.answerModel.SC06 = 0;
    this.answerModel.SC07 = 0;
    this.answerModel.SC08 = 0;
    this.answerModel.SC09 = 0;
    this.answerModel.SC10 = 0;
    this.answerModel.SC11 = 0;
    this.answerModel.SC12 = 0;
    this.answerModel.SC13 = 0;
    this.answerModel.SC14 = 0;
    this.answerModel.SC15 = 0;
    this.questionsAnswered[2] = 14;
    // console.log(this.answerModel);
  }
}
