import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
// import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// npm install --save file-saver
import { Component, ElementRef} from '@angular/core';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

interface Municipality {
  name_1: string;
  name_2: string;
  code: number;
}

type AOA = any[][];

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {

  public testdata: any = [];
  public edited = false; // show hide header message

  public loading = false; // for file loading
  public ProductPerPage: 4; // pagination
  public SelectedPage = 1; // pagination
  public selectedCategory = null; // pagination
  //
  constructor(private router: Router, public rest: WebserviceCallerService) { } // injecting for refresh the page feature
  //
  data: AOA = [['Responded Date', 'Municipality', 'Question A', 'Question B', 'Question C', 'Question D'],
    ['1/30/2020 9:23', 'Lappeenranta', 4, 5, 3, 1]]; // test data generated
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'Ysimittari.xlsx';

  importAsExcel(evt: any) {

    /* wire up file reader */
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true, dateNF: 'DD-MM-YYYY HH:mm'});
      // , cellDates: true, dateNF: 'DD-MM-YYYY HH:mm'

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */

      this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 })) as AOA;
      console.log(this.data);
    };
    this.loadingData(); // show the loading animation
    reader.readAsBinaryString(target.files[0]);

  }
  exportAsExcel(): void {

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  // export as CSV File
  exportCSVFile(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'Ysimittari.csv');
  }

  // Refresh the page
  refreshPage = function() {// please include reportComponent in app-routing.module.ts
    this.router.navigateByUrl(['/report']);
  };

  // Show and hide message function
  saveTodos(): void {// need improvement
    // show box msg
    this.edited = true;
    // wait 3 Seconds and hide
    setTimeout(function() {
      this.edited = false;
      console.log(this.edited);
    }.bind(this), 3000);
  }

  loadingData() { // Trigering the loading activated during file upload
    this.loading = true;
    setTimeout(() => this.loading = false, 2000);
  }
  /* get excelsFile():data[]{
 let indexPage=(this.SelectedPage-1)*this.ExcelFile
 return this.  }*/ // code for pagination




}
