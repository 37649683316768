<div class="login-wrapper jumbotron" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>{{this.forgetPasswordComponentItems.mtee_app_fp_header}}</mat-card-title>
      <mat-card-subtitle>{{this.forgetPasswordComponentItems.mtee_app_fp_instruction}}</mat-card-subtitle>
    </mat-card-header>

    <form class="formRecovery" id="formRecovery" [formGroup]="formRecovery">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input type="text" id="username" matInput placeholder="{{this.forgetPasswordComponentItems.mtee_app_fp_eml_plchldr}}" formControlName="username">
        </mat-form-field>
      </mat-card-content>
      <mat-error *ngIf="formRecovery.controls['username'].hasError('minlength')">
        {{getFormErrorTextEmail()}}
      </mat-error>
      <button mat-stroked-button class="action-button btn-block" (click) = "recoverUser()" [disabled]="!formRecovery.valid">{{this.forgetPasswordComponentItems.mtee_app_fp_reset_btn}}</button>
    </form>
  </mat-card>

</div>
