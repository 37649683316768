<div class="container-lg col-md-10">
  <div class="jumbotron">
   <mat-card>


          <mat-horizontal-stepper labelPosition="bottom">

            <mat-step *ngFor="let step of listTheme; let index = index; let last = last;">

              <ng-template matStepLabel>
                <span class="text-wrap">{{ListByThemeName[index].mtee_question_theme_display_name[selectedLanguage]}}
                </span>
              </ng-template>

              <div>
                <p class="mb-0 font-weight-bold ThemeInstruction"> {{ListByThemeName[index].mtee_question_theme_instruction[selectedLanguage]}} </p>
              </div>

              <div class="progress">
                <mat-progress-bar mode="determinate" value={{progressBarValue}}>{{progress}}</mat-progress-bar>
              </div>
              <!--Questions on Page: {{step.length}}  | Total Answered {{progress}}  | Previously Answered: {{totalAnswered}} | Total Questions: {{totalQuestions}} | Page Question :{{pageQuestionLength}}
        -->
              <mat-card-content *ngFor="let question of step; index as i">

                <mat-card>
                               <p class="bigger-font QuestionText">{{i+1}}.  {{question.mtee_question_text[selectedLanguage]}}</p>
                               <div *ngIf="question.questionType == 'Radio'">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" >
                                             <mat-radio-button mat-button  type="button" class="example-radio-button"
                                                               *ngFor="let option of question.options ;index as j;"
                                                               [value]="question.options[j].mtee_question_option_value"
                                                              (click)="answeredQuestion($event, question.mtee_question_id, question.options[j].mtee_question_option_value, question.questionType, index)">
                                                           {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                             </mat-radio-button>
                                    </mat-radio-group>
                               </div>

                               <div *ngIf="question.questionType == 'Range'">
                                    <mat-slider thumbLabel #scaleSize (change)="updateSetting($event, question.mtee_question_id, question.questionType, index)" step="1" min="0" max="30" thumbLabel tickInterval="1" [value]="scaleSize"></mat-slider>
                               </div>

                               <div *ngIf="question.questionType == 'Special'">
                                 <mat-form-field appearance="outline">
                                   <mat-icon>language</mat-icon>
                                   <mat-select (selectionChange)="onSelectionCountry($event, question.mtee_question_id, question.questionType, index)" placeholder=""[(value)]="selectedCountry">
                                     <mat-option  *ngFor="let country of countryList" [value]="country">
                                                                          {{country}}
                                     </mat-option>
                                   </mat-select>
                                 </mat-form-field>
                               </div>
                </mat-card>
              </mat-card-content>

              <div>
                <button mat-button mat-raised-button type="button" class ="left" *ngIf="index !== 0" matStepperPrevious (click)="prevStep(index)">{{questionComponentItems.backButton}}</button>
                <button mat-button mat-raised-button type="button" class ="right"  [disabled]="(questionsInPage[index] != questionsAnswered[index])" *ngIf="!last" matStepperNext (click)="nextStep(index)">{{questionComponentItems.nextButton}}</button>
                <button mat-button mat-raised-button type="button" class ="submit"  [disabled]="progress != totalQuestions" *ngIf="last" (click)="submit()">{{questionComponentItems.submitButton}}</button>
              </div>

            </mat-step>
          </mat-horizontal-stepper>
        </mat-card>
  </div>
</div>

