/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./settings.component";
import * as i4 from "../data-share.service";
import * as i5 from "ngx-cookie-service";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [["class", "container-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 30, "div", [["class", "jumbotron"], ["style", "background-color: rgb(255, 255, 255);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 29, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Settings Panel"])), (_l()(), i1.ɵeld(6, 0, null, null, 25, "div", [["class", "col-md-12 jumbotron"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 24, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "col-lg-3 col-md-3 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵted(-1, null, ["Generate Simple Report Between Dates"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "col-lg-3 col-md-3 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, ["Generate Filtered Report"])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "col-lg-3 col-md-3 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 3, "div", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(24, 1), (_l()(), i1.ɵted(-1, null, ["Change Report Headings"])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "div", [["class", "col-lg-3 col-md-3 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 3, "div", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(30, 1), (_l()(), i1.ɵted(-1, null, ["Create New Report Type"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 12, 0, "/reportByDate"); _ck(_v, 11, 0, currVal_0); var currVal_1 = _ck(_v, 18, 0, "/reportAdvanced"); _ck(_v, 17, 0, currVal_1); var currVal_2 = _ck(_v, 24, 0, "/reportHeadings"); _ck(_v, 23, 0, currVal_2); var currVal_3 = _ck(_v, 30, 0, "/reportTypeNew"); _ck(_v, 29, 0, currVal_3); }, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SettingsComponent, [i4.DataShareService, i5.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i3.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
