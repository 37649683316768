<!--   <div class="  appExitComponent" fxLayout="row" fxLayoutAlign="center initial">
  <div class="jumbotron">
    <p>Get Report!</p>
    <form class="formReport" id="formReport" [formGroup]="formReport">
      <mat-form-field class="example-full-width">
         <input type="text" id="reportKey" matInput placeholder="Enter Report Key" formControlName="reportKey">
      </mat-form-field>
    </form>
    <button button mat-stroked-button color="accent" class="btn-sm" (click) = "downloadData()">
                               Download
    </button>
    <p class = 'errorMessage' >{{this.errorMsg}}</p>
  </div>
</div>
-->
<div class="container">
  <div *ngIf="data">
    <h3 class="text-center">You have <span class="text-warning">{{ data.length-1 }}</span> column data in your report
    </h3>


    <div class="row">
      <div class="col-lg-12 overflow-auto">
        <!--scroll horizontally-->
        <div *ngIf="!edited" class="bg-info" role="alert">

        </div>
        <div class="table-responsive-lg">
        <table class="table">
          <tbody>
          
            <tr *ngFor="let row of data">
              <td *ngFor="let val of row">
                {{val}}
              </td>
            </tr>
          
          </tbody>
        </table>
      </div>

        <!--Add matPaginatorModule to app.module.ts-->
      </div>
     
    </div>
  </div>
  <div style="padding-bottom: 10px;padding-top: 10px;">
    <div class="row">
      <div class="col-lg-2">
        <button class="btn btn-success btn-block" (click)="exportAsExcel()"><i class="material-icons">save_alt</i>Export
          as Excel File</button>
      </div>
      <div class="col-lg-2">
        <button class="btn btn-outline-success btn-block" (click)="exportCSVFile(data)"><i
            class="material-icons">save_alt</i>Export as CSV file</button>

      </div>
      <div class="col-lg-2">
        <button class="btn  btn-outline-primary btn-block"><i class="material-icons">send</i>Send Email</button> </div>
      <div class="col-lg-2">
        <button class="btn btn-outline-success btn-lg btn-block" (click)="refreshPage()"><i
            class="material-icons">refresh</i>Refresh</button>
      </div>
      <div class="col-lg-4">

        <input type="file" (change)="importAsExcel($event)" multiple="false" accept=".xlsx">

        <div *ngIf="loading">
          <mat-progress-bar [mode]="'indeterminate'">
          </mat-progress-bar>
          <p class="">Importing...</p>
        </div>
      </div>

      <!--
1)Scrollable data
2)Show counts of data, equal size with
3)Shows the processing the the data(input file), appropriate and user friendly color.)
4) Show and select particular amoount of data for review ....in progress --it shows the number of data in table 


-->




    </div>
    <div class="row">
      <div class="col-lg-3">
        List of reports as custom *extra feature as idea
        <ul class="list-group">
          <li class="list-group-item list-group-item-action">England(155)<span
              class="badge badge-primary badge-pill">35</span></li>
          <li class="list-group-item list-group-item-action">Belgium <span
              class="badge badge-primary badge-pill">2</span></li>
          <li class="list-group-item list-group-item-action">Italy <span
              class="badge badge-primary badge-pill">14</span></li>
          <li class="list-group-item list-group-item-action">Macedonia <span
              class="badge badge-primary badge-pill">144</span></li>
          <li class="list-group-item list-group-item-action">Spain <span
              class="badge badge-primary badge-pill">50</span></li>
          <li class="list-group-item list-group-item-action">Walse <span
              class="badge badge-primary badge-pill">6</span></li>
         
        </ul>
      </div>
    </div>

  </div>



  <h1>Create New Report</h1>

  <div class="row">
    <div class="col-md-12">
      <p>Choose the date range</p>
      <p>Choose Municipilaty</p>
    </div>
  </div>
</div>