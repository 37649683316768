import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  infoComponentItems: any = {};
  constructor(private data: DataShareService, private  cookieService: CookieService) { }

  ngOnInit() {
    this.data.infoPageStatic.subscribe(message => this.infoComponentItems = message);
    /*if ( this.cookieService.get('UserId') === '*') {
      this.cookieService.set('UserId', '', undefined, '/', undefined, true);
    }*/
  }
}
