import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataShareService {
    //
    constructor() {
        //
        this.messageSource = new BehaviorSubject('');
        this.navigationHeaders = new BehaviorSubject('');
        this.questionPage = new BehaviorSubject('');
        this.selectedLanguage = new BehaviorSubject('');
        this.registerPage = new BehaviorSubject('');
        this.loginPage = new BehaviorSubject('');
        this.loginResult = new BehaviorSubject('');
        this.feedbackPage = new BehaviorSubject('');
        this.infoPage = new BehaviorSubject('');
        this.faqPage = new BehaviorSubject('');
        this.forgetPasswordPage = new BehaviorSubject('');
        this.resetPasswordPage = new BehaviorSubject('');
        this.changePasswordPage = new BehaviorSubject('');
        this.userDeviceInfo = new BehaviorSubject('');
        this.errorMessageList = new BehaviorSubject('');
        this.successMessageList = new BehaviorSubject('');
        //
        this.currentMessage = this.messageSource.asObservable();
        this.navigationItems = this.navigationHeaders.asObservable();
        this.questionPageStatic = this.questionPage.asObservable();
        this.registerPageStatic = this.registerPage.asObservable();
        this.loginPageStatic = this.loginPage.asObservable();
        this.userLoginResult = this.loginResult.asObservable();
        this.feedbackPageStatic = this.feedbackPage.asObservable();
        this.currentLanguage = this.selectedLanguage.asObservable();
        this.infoPageStatic = this.infoPage.asObservable();
        this.faqPageStatic = this.faqPage.asObservable();
        this.forgetPageStatic = this.forgetPasswordPage.asObservable();
        this.resetPageStatic = this.resetPasswordPage.asObservable();
        this.changePageStatic = this.changePasswordPage.asObservable();
        this.userDeviceInfoStatic = this.userDeviceInfo.asObservable();
        this.errorMessageListStatic = this.errorMessageList.asObservable();
        this.successMessageListStatic = this.successMessageList.asObservable();
    }
    //
    changeMessage(message) {
        this.messageSource.next(message);
    }
    //
    changeNavigation(message) {
        this.navigationHeaders.next(message);
    }
    //
    changeLanguage(language) {
        this.selectedLanguage.next(language);
    }
    //
    changeRegisterPageStatic(message) {
        this.registerPage.next(message);
    }
    //
    changeLoginPageStatic(message) {
        this.loginPage.next(message);
    }
    //
    storeLoginResult(message) {
        this.loginResult.next(message);
    }
    //
    changeFeedbackPageStatic(message) {
        this.feedbackPage.next(message);
    }
    //
    changeQuestionPageStatic(message) {
        this.questionPage.next(message);
    }
    //
    changeInfoPageStatic(message) {
        this.infoPage.next(message);
    }
    //
    changeFaqPageStatic(message) {
        this.faqPage.next(message);
    }
    //
    changeForgetPasswordPageStatic(message) {
        this.forgetPasswordPage.next(message);
    }
    //
    changeResetPasswordPageStatic(message) {
        this.resetPasswordPage.next(message);
    }
    //
    changeChangePasswordPageStatic(message) {
        this.changePasswordPage.next(message);
    }
    //
    storeUserDeviceInfoStatic(message) {
        this.userDeviceInfo.next(message);
    }
    //
    storeErrorMessageListStatic(message) {
        this.errorMessageList.next(message);
    }
    //
    storeSuccessMessageListStatic(message) {
        this.successMessageList.next(message);
    }
}
DataShareService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataShareService_Factory() { return new DataShareService(); }, token: DataShareService, providedIn: "root" });
