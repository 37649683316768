import * as i0 from "@angular/core";
import * as i1 from "./data-share.service";
export class ShowMessageService {
    constructor(dataBus) {
        this.dataBus = dataBus;
        this.errorList = [];
        this.successMessageList = [];
        this.errorBody = [];
        this.successMessageBody = [];
        this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
        this.dataBus.successMessageListStatic.subscribe(successList => this.successMessageList = successList);
        this.dataBus.errorMessageListStatic.subscribe(errorList => this.errorList = errorList);
    }
    //
    getErrorMessageByCode(code) {
        const codeToSearch = parseInt(code, 0);
        const data = this.errorList;
        for (const messages of data) {
            const codeToCompare = parseInt(messages.mtee_code, 0);
            console.log('Comparing : ' + codeToSearch + ' with : ' + codeToCompare);
            if (codeToSearch === codeToCompare) {
                console.log('Found Error Code : ');
                this.errorBody = messages.mtee_message_text;
                return this.errorBody;
            }
        }
    }
    //
    getSuccessMessageByCode(code) {
        const codeToSearch = parseInt(code, 0);
        const data = this.successMessageList;
        for (const messages of data) {
            const codeToCompare = parseInt(messages.mtee_code, 0);
            // console.log('Comparing : ' + codeToSearch + ' with : ' + codeToCompare);
            if (codeToSearch === codeToCompare) {
                // console.log('Found Success Code : ');
                this.successMessageBody = messages.mtee_message_text;
                return this.successMessageBody;
            }
        }
    }
}
ShowMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShowMessageService_Factory() { return new ShowMessageService(i0.ɵɵinject(i1.DataShareService)); }, token: ShowMessageService, providedIn: "root" });
