import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import {ShowMessageService} from '../show-message.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataShareService} from '../data-share.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  errorMsg: any = {};
  successMsg: any = {};
  public selectedLanguage: string;
  constructor(private router: Router,
              public cookieService: CookieService,
              private dataBus: DataShareService,
              public showMessageService: ShowMessageService,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.logoutUser();
  }
  logoutUser() {
    // console.log('Inside Logout');
    this.cookieService.set('UserId', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', undefined, true, "None");
    this.cookieService.set('showFeedback', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', undefined, true, "None");
    window.localStorage.clear();
    this.cookieService.delete('UserId');
    this.cookieService.delete('showFeedback');
    this.successMsg = this.showMessageService.getSuccessMessageByCode('907');
    this.snackBar.open(this.successMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
                });
    this.router.navigate(['']);
  }
}
