<div class="container-lg">

  <div class="jumbotron" style="background-color: rgb(255, 255, 255);">
    <div class="row">
      <div class="col-md-12">
        <h2>Settings Panel</h2>
      </div>
      <div class="col-md-12 jumbotron">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportByDate']">Generate Simple Report Between Dates</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportAdvanced']">Generate Filtered Report</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportHeadings']">Change Report Headings</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportTypeNew']">Create New Report Type</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
