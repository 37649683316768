import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieService} from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatTableModule,
  MatDividerModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSliderModule,
  MatExpansionModule,
  MatSnackBarModule,
} from '@angular/material';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LogService } from './logger.service';
import { WebserviceCallerService } from './webservice-caller.service';
import { DataShareService } from './data-share.service';
import { ShowMessageService } from './show-message.service';
import { QuestionsComponent } from './questions/questions.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LoginhelpersModule } from './loginhelpers/loginhelpers.module';
import { LogInComponent } from './log-in/log-in.component';
import { RegisterComponent } from './register/register.component';
import { LogoutComponent } from './logout/logout.component';
import { SettingsComponent } from './settings/settings.component';
import { InfoComponent } from './info/info.component';
import { FaqComponent } from './faq/faq.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ReportComponent } from './report/report.component';
import { ResetComponent } from './reset/reset.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ReportByDateComponent } from './report-by-date/report-by-date.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuestionsComponent,
    FeedbackComponent,
    LogInComponent,
    RegisterComponent,
    LogoutComponent,
    SettingsComponent,
    InfoComponent,
    FaqComponent,
    ForgetpasswordComponent,
    ReportComponent,
    ResetComponent,
    ChangepasswordComponent,
    ReportByDateComponent,
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatDividerModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        MatStepperModule,
        MatProgressBarModule,
        FormlyBootstrapModule,
        FormlyModule.forRoot({
            validationMessages: [
                {name: 'required', message: 'This field is required'},
            ],
        }),
        MatRadioModule,
        MatSliderModule,
        LoginhelpersModule,
        MatExpansionModule,
        MatSnackBarModule,
    ],
  providers: [
    LogService,
    WebserviceCallerService,
    DataShareService,
    ShowMessageService,
    CookieService,
    DeviceDetectorService,
    HomeComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
