import { Injectable } from '@angular/core';
import {DataShareService} from './data-share.service';
import {stringify} from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class ShowMessageService {
  public selectedLanguage: string;
  public errorList: any = [];
  public successMessageList: any = [];
  public errorBody: any = [];
  public successMessageBody: any = [];
  constructor(private dataBus: DataShareService) {
    this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.dataBus.successMessageListStatic.subscribe(successList => this.successMessageList = successList);
    this.dataBus.errorMessageListStatic.subscribe(errorList => this.errorList = errorList);
  }
  //
  public getErrorMessageByCode(code: string) {
    const codeToSearch = parseInt(code, 0);
    const data = this.errorList;
    for (const messages of (data as any)) {
      const codeToCompare = parseInt(messages.mtee_code, 0);
      console.log('Comparing : ' + codeToSearch + ' with : ' + codeToCompare);
      if (codeToSearch === codeToCompare) {
        console.log('Found Error Code : ');
        this.errorBody = messages.mtee_message_text;
        return this.errorBody;
      }
    }
  }
  //
  public getSuccessMessageByCode(code: string) {
    const codeToSearch = parseInt(code, 0);
    const data = this.successMessageList;
    for (const messages of (data as any)) {
      const codeToCompare = parseInt(messages.mtee_code, 0);
      // console.log('Comparing : ' + codeToSearch + ' with : ' + codeToCompare);
      if (codeToSearch === codeToCompare) {
        // console.log('Found Success Code : ');
        this.successMessageBody = messages.mtee_message_text;
        return this.successMessageBody;
      }
    }
  }
}
