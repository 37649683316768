import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

 infoComponentItems: any = {};
  constructor(private data: DataShareService, private  cookieService: CookieService) { }

  ngOnInit() {
    this.data.infoPageStatic.subscribe(message => this.infoComponentItems = message);
    /*if ( this.cookieService.get('UserId') === '*') {
      this.cookieService.set('UserId', '', undefined, '/', undefined, true);
    }*/
  }

}
