import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqComponentItems: any = {};
  constructor(private data: DataShareService, private  cookieService: CookieService) { }

  ngOnInit() {
    this.data.faqPageStatic.subscribe(message => this.faqComponentItems = message);
    /*if ( this.cookieService.get('UserId') === '*') {
      this.cookieService.set('UserId', '', undefined, '/', undefined, true);
    }*/
  }

}
